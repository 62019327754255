<template>
<div>
    <v-navigation-drawer v-if="drawer" :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'" app right>
        <div class="text-center ">
            <v-avatar tile border size="200">
                <v-img contain src="@/assets/images/logo.png"></v-img>
            </v-avatar>

            <v-divider vertical="" inset="" class="mx-2" />
        </div>

        <tree />

        <v-divider />
        <template>
            <div class="pa-2 text-center">
                <!-- <v-avatar size="100">
                    <v-img src="@/assets/images/logo.png"/>
                </v-avatar><br/> -->
                <span style="color: #7c7c7c">
                    تم التطوير بواسطة<br/>
                    <a href="https://google.co/">شركة السحابة الذكية لتكنولوجيا المعلومات</a>
                    | {{ new Date().getFullYear() }}
                </span>
            </div>
        </template>
    </v-navigation-drawer>
</div>
</template>

<script>
export default {
    components: {
        tree: () => import('./Tree.vue'),
        // profile: () => import('./Profile'),
    },
     
    data() {
        return {
            loading: false,
            currentBalance: [{
                id: "3eb1ccc4-3b88-4119-8c06-c5db13fabcc6",
                totalBalance: 0,
                walletName: "",
            }]
        }
    },
    props: ['drawer'],
    methods: {
        openAddWithdrawDialog() {
            var item = {
                dialogType: 'add',
                title: 'سحب'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog3')
        },
        
        openAddDepositDialog() {
            var item = {
                dialogType: 'add',
                title: 'ايداع'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog4')
        },

        logout() {
            this.loading = true
            this.$http.delete('logout').finally(() => {
                this.loading = false
                sessionStorage.removeItem("token");
                this.$router.push('/login');
            })
        },

        async getMyBalance() {
            let res = await this.$http.get(`Transaction/MyBalance`)
            console.log('res.data')
            console.log(res)
            this.currentBalance = res.data.result
        }
    },

    created() {
        // this.getMyBalance()
        // setTimeout(() => {
        //     this.$eventBus.$on(`refresh-balance`, () => {
        //         this.getMyBalance()
        //     })
        // }, 100);
    },

}
</script>
